import React from "react";
// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";

// material-ui icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { makeStyles } from "@material-ui/core/styles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
// apis
import SubscriptionBookingService from "services/SubscriptionBookingService";
import useAPI from "utils/useAPI";
import { useHistory } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Slide from "@material-ui/core/Slide";

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import SmsIcon from "@material-ui/icons/Sms";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";

const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const vehicleStatusList = [
  { id: 0, name: "unparked" },
  { id: 1, name: "parked" },
  { id: 2, name: "retrieved" },
];

export default function SubscriptionBooking() {
  const api = useAPI();
  const history = useHistory();
  let path = "/user-subscription-booking/";
  const tableRef = React.useRef();
  const [modal, setModal] = React.useState(false);
  const [vehicleStatus, setVehicleStatus] = React.useState("");
  const [ticketId, setTicketId] = React.useState(0);
  const classes = useStyles();

  const changeStatus = async (data) => {
    await SubscriptionBookingService.updateCurrentSubscriptionBooking(data);
  };

  React.useEffect(() => {
    SubscriptionBookingService.init(api);
  }, [api]);

  const filterString = (value) => {
    return value ? value : "-";
  };

  const formatePhone = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  function transform(session) {
    let transformed_subscriptions = session;
    transformed_subscriptions = transformed_subscriptions.map((ses) => {
      return {
        id: ses.id,
        name: filterString(ses.user.name),
        phone: (
          <>
            {String(formatePhone(ses.user.phone)).toUpperCase() + " "}
            <div display="flex">
              <Tooltip title="Send SMS" aria-label="">
                <IconButton
                  onClick={() => {
                    setModal(true);
                    // setVehicleStatus("parked");
                    // setTicketId(ses.id);
                    // changeStatus({
                    //   id: ses.id,
                    //   vehicle_status: "parked",
                    // }).then(() => {
                    //   tableRef.current && tableRef.current.onQueryChange();
                    // });
                    // setVehicleStatus("");
                    // setTicketId(0);
                  }}
                >
                  <SmsIcon />
                </IconButton>
              </Tooltip>
            </div>
          </>
        ),
        vehicle_name: ses.vehicle_name,
        vehicle_type: ses.vehicle_type,
        ticketUrl: ses.ticketUrl,
        vehicle_status: (
          <>
            {String(ses.vehicle_status).toUpperCase() + " "}
            <div display="flex">
              {ses.vehicle_status == "unparked" && (
                <Tooltip title="Park" aria-label="">
                  <IconButton
                    onClick={() => {
                      // setVehicleStatus("parked");
                      // setTicketId(ses.id);
                      changeStatus({
                        id: ses.id,
                        vehicle_status: "parked",
                      }).then(() => {
                        tableRef.current && tableRef.current.onQueryChange();
                      });
                      // setVehicleStatus("");
                      // setTicketId(0);
                    }}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                </Tooltip>
              )}
              {ses.vehicle_status == "parked" && (
                <Tooltip title="Retrieve" aria-label="">
                  <IconButton
                    onClick={() => {
                      // setVehicleStatus("retrieved");
                      // setTicketId(ses.id);
                      changeStatus({
                        id: ses.id,
                        vehicle_status: "retrieved",
                      }).then(() => {
                        tableRef.current && tableRef.current.onQueryChange();
                      });
                      // setVehicleStatus("");
                      // setTicketId(0);
                    }}
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </>
        ),
      };
    });
    return transformed_subscriptions;
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title="Subscription Bookings"
            tableRef={tableRef}
            columns={[
              { title: "Name", field: "name" },
              { title: "Phone", field: "phone" },
              { title: "Vehicle", field: "vehicle_name" },
              { title: "Vehicle Type", field: "vehicle_type" },

              // { title: "Exit", field: "vehicle_exit" },
              // { title: "Vehicle Damage", field: "vehicle_damage" },
              {
                title: "Vehicle Status",
                field: "vehicle_status",
                cellStyle: { fontSize: 12 },
              },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                SubscriptionBookingService.getSubscriptionBookings(
                  query.pageSize,
                  query.page + 1,
                  query.search,
                  query.orderBy ? query.orderBy.field : null,
                  query.orderDirection
                )
                  .then((res) => {
                    resolve({
                      data: transform(res.results),
                      page: res.page - 1,
                      totalCount: res.total,
                    });
                  })
                  .catch(reject);
              })
            }
            actions={[
              // {
              //   icon: "add",
              //   tooltip: "Add Ticket",
              //   position: "toolbar",
              //   isFreeAction: true,
              //   onClick: () => {
              //     history.push("/ticket/create");
              //   },
              // },

              (rowData) => ({
                icon: "edit",
                iconProps: { fontSize: "small" },
                tooltip: "Manage Subscription",
                onClick: () => {
                  // history.push(path + rowData.id);
                },
              }),
              (rowData) => ({
                icon: () => (
                  <VisibilityIcon
                    style={{ color: "#00acc1" }}
                    fontSize="small"
                  />
                ),
                iconProps: { fontSize: "small" },
                tooltip: "Booking History",
                onClick: () => {
                  history.push(path + rowData.id + "/history");
                },
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: "center", paddingRight: "0%" }}>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <CollectionsBookmarkIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 500,
              pageSize: 10,
            }}
          />
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setModal(false);
              setVehicleStatus("");
              setTicketId(0);
            }}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <IconButton
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => {
                  setModal(false);
                  setVehicleStatus("");
                  setTicketId(0);
                }}
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <h4 className={classes.modalTitle}>Send SMS</h4>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              <TextareaAutosize
                minRow={5}
                aria-label="minimum height"
                placeholder="Enter Your Message Here"
                // defaultValue="Dear Customer, "
              />
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                onClick={() => {
                  setModal(false);
                  setVehicleStatus("");
                  setTicketId(0);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setModal(false);
                  console.log(vehicleStatus);
                  // changeStatus({
                  //   id: ticketId,
                  //   vehicle_status: vehicleStatus,
                  // }).then(() => {
                  //   console.log("Completed");
                  //   tableRef.current && tableRef.current.onQueryChange();
                  // });
                  // setVehicleStatus("");
                  // setTicketId(0);
                }}
                color="primary"
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
