import Carousels from "views/Tables/Carousels";
import Users from "views/Tables/Users.js";
import Transactions from "views/Tables/Transactions.js";
import Vehicles from "views/Tables/Vehicles";
// import organization from "views/Tables/Organization";
import FeeStructure from "views/Tables/FeeStructure";
import Pins from "views/Tables/Pins";
import GroupIcon from "@material-ui/icons/Group";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ListAltIcon from "@material-ui/icons/ListAlt";
import FiberPinIcon from "@material-ui/icons/FiberPin";
import ReceiptIcon from "@material-ui/icons/Receipt";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ParkingSessions from "views/Tables/ParkingSessions";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import Discount from "views/Tables/Discount";
import Location from "views/Tables/Location";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
// import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import UnsubscribeIcon from "@material-ui/icons/Unsubscribe";
import ConfirmationNumberSharpIcon from "@material-ui/icons/ConfirmationNumberSharp";
import Ticket from "./views/Tables/Ticket";
import Subscription from "./views/Tables/Subscription";
import SubscriptionBooking from "./views/Tables/SubscriptionBooking";

var dashRoutes = [
  {
    path: "/users",
    name: "Users",
    mini: "US",
    component: Users,
    icon: GroupIcon,
    layout: "/admin",
  },
  // {
  //   path: "/organization",
  //   name: "Organizations",
  //   mini: "VH",
  //   icon: AccountBalanceIcon,
  //   component: organization,
  //   layout: "/admin",
  // },
  {
    path: "/location",
    name: "Locations",
    mini: "VH",
    icon: LocationOnIcon,
    component: Location,
    layout: "/admin",
  },
  {
    path: "/vehicles",
    name: "Vehicles",
    mini: "VH",
    icon: LocalShippingIcon,
    component: Vehicles,
    layout: "/admin",
  },
  {
    path: "/carousels",
    name: "Carousels",
    mini: "CA",
    icon: ListAltIcon,
    component: Carousels,
    layout: "/admin",
  },
  {
    path: "/pins",
    name: "Pins",
    mini: "CA",
    icon: FiberPinIcon,
    component: Pins,
    layout: "/admin",
  },
  {
    path: "/transactions",
    name: "Transactions",
    mini: "CA",
    icon: ReceiptIcon,
    component: Transactions,
    layout: "/admin",
  },
  {
    path: "/parking-sessions",
    name: "Parking Sessions",
    mini: "PS",
    icon: LocalParkingIcon,
    component: ParkingSessions,
    layout: "/admin",
  },
  {
    path: "/discount",
    name: "Discount",
    mini: "PS",
    icon: LocalAtmIcon,
    component: Discount,
    layout: "/admin",
  },
  {
    path: "/fee-structure",
    name: "Fee Structure",
    mini: "CA",
    icon: MonetizationOnIcon,
    component: FeeStructure,
    layout: "/admin",
  },
  {
    path: "/ticket",
    name: "Ticket",
    mini: "CA",
    icon: ConfirmationNumberSharpIcon,
    component: Ticket,
    layout: "/admin",
  },
  {
    path: "/user-subscription-bookings",
    name: "Subscription Bookings",
    mini: "CA",
    icon: CollectionsBookmarkIcon,
    component: SubscriptionBooking,
    layout: "/admin",
    hidden: false,
  },
  {
    path: "/subscription",
    name: "Subscriptions",
    mini: "CA",
    icon: UnsubscribeIcon,
    component: Subscription,
    layout: "/admin",
  },
];

export default dashRoutes;
