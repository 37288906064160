import React from "react";
// react plugin for creating charts
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Language from "@material-ui/icons/Language";
import ListAltIcon from "@material-ui/icons/ListAlt";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import DashboardService from "services/DashboardService";
import useAPI from "utils/useAPI";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { useHistory } from "react-router-dom";

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

const useStyles = makeStyles(styles);

export default function MainDashboard() {
  const classes = useStyles();
  const api = useAPI();
  const [userCount, setuserCount] = React.useState("");
  const [vehicleCount, setvehicleCount] = React.useState("");
  const [parkedVehicleCount, setparkedVehicleCount] = React.useState("");
  const history = useHistory();

  const fetchDashboardData = async () => {
    DashboardService.getDashboardData().then((res) => {
      setparkedVehicleCount(res.parked_vehicle);
      setvehicleCount(res.vehicle_count);
      setuserCount(res.user_count);
    });
  };
  React.useEffect(() => {
    DashboardService.init(api);
    fetchDashboardData();
  }, [api]);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card
            onClick={() => history.push("/admin/users")}
            style={{ cursor: "pointer" }}
          >
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Users</p>
              <h3 className={classes.cardTitle}>{userCount}</h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card
            onClick={() => history.push("/admin/vehicles")}
            style={{ cursor: "pointer" }}
          >
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <LocalShippingIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Vehicles</p>
              <h3 className={classes.cardTitle}>{vehicleCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}></div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card
            onClick={() => history.push("/vehicles")}
            style={{ cursor: "pointer" }}
          >
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <LocalParkingIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Parked Vehicles</p>
              <h3 className={classes.cardTitle}>{parkedVehicleCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}></div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <ListAltIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Carousels</p>
              <h3 className={classes.cardTitle}>3</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}></div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Language />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Parking Locations in Uninted States
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <VectorMap
                    map={"us_aea"}
                    backgroundColor="transparent"
                    zoomOnScroll={true}
                    containerStyle={{
                      width: "100%",
                      height: "280px",
                    }}
                    containerClassName="map"
                    regionStyle={{
                      initial: {
                        fill: "#e1e1e1e1",
                        "fill-opacity": 0.9,
                        stroke: "none",
                        "stroke-width": 0,
                        "stroke-opacity": 0,
                      },
                    }}
                    series={{
                      regions: [
                        {
                          values: mapData,
                          scale: ["#AAAAAA", "#444444"],
                          normalizeFunction: "polynomial",
                        },
                      ],
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
